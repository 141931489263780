import Vue from 'vue'
import VueRouter from 'vue-router'
import VueSocketIO from 'vue-socket.io'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Accueil from './components/Accueil.vue'
import Mandater from './components/Mandater.vue'
import Prevoyance from './components/Prevoyance.vue'
import Formulaire from './components/Formulaire.vue'
import Contact from './components/Contact.vue'

Vue.config.productionTip = false
Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://ns321946.ip-37-187-5.eu:3000'
}))
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: Accueil,
      name: 'accueil'
    },
    {
      path: '*',
      component: Accueil,
      name: 'accueil'
    },
    {
      path: '/mandater',
      component: Mandater,
      name: 'mandater'
    },
    {
      path: '/prevoyance',
      component: Prevoyance,
      name: 'prevoyance'
    },
    {
      path: '/contact',
      component: Contact,
      name: 'contact'
    },
    {
      path: '/formulaire/:token',
      component: Formulaire,
      name: 'formulaire'
    }
  ]
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
