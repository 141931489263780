<template>
    <v-container>
        <h1 class="display-2 font-weight-bold text-center">Formulaire de contact</h1>
        <div class="myForm pa-6">
            <v-form lazy-validation ref="demande">
                <v-row justify="start">
                    <v-col cols="12" sm="6">
                        <v-text-field :rules="nameRules" label="Prénom" required v-model="ask.prenom"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field :rules="nameRules" label="Nom" required v-model="ask.nom"></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="start">
                    <v-col cols="12" sm="6">
                        <v-text-field :rules="emailRules" type="email" label="E-mail" required v-model="ask.email"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field :rules="phoneRules" v-mask="'### ### ## ##'" type="tel" label="Téléphone" required v-model="ask.telephone"></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="start">
                    <v-col cols="12">
                        <v-textarea label="Votre message" :rules="nameRules" v-model="ask.message"></v-textarea>
                    </v-col>
                </v-row>
                <vue-recaptcha sitekey="6Lcb0uIUAAAAAAX5_D5Bx4NDBm-ofukA8Et527uM"></vue-recaptcha>
                <div class="text-center">
                    <v-btn @click="sendContactForm" class="primary" large>Envoyer</v-btn>
                </div>
            </v-form>
        </div>
        <v-dialog v-model="askSended" width="500">
            <v-card>
              <v-card-title class="headline">Votre message nous est parvenu !</v-card-title>
              <v-card-text class="body-1">
                Nous allons vous contacter les plus brefs délais. Si après 1 heure nous ne vous avons pas contacté, veuillez nous téléphoner au 026 322 86 86. Merci 
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="returnAccueil">
                  Fermer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
    </v-container>
</template>
  
  <script>
    import VueRecaptcha from 'vue-recaptcha'
    import { mask } from 'vue-the-mask'
    export default {
      directives: {
        mask,
      },
      data: () => ({
        askSended: false,
        ask: {
            prenom: '',
            nom: '',
            email: '',
            telephone: '',
            message: ''
        },
        nameRules: [
          v => !!v || 'Requis !'],
        emailRules: [
          v => !!v || 'Requis !',
          v => /.+@.+\..+/.test(v) || 'Veuillez entrer un e-mail valide !'
        ],
        phoneRules: [
          v => !!v || 'Requis !',
          v => (v && v.length >= 13) || 'Veillez entrer un numéro de téléphone valide !'
        ]
      }),
      methods: {
        sendContactForm(){
            if(window.grecaptcha.getResponse()!=''){
                if(this.$refs.demande.validate()){
                    let askData = {
                    token: window.grecaptcha.getResponse(),
                    choice: this.checkBox,
                    persoInfo: this.ask,
                    service: "service funèbre"
                    }
                    this.$socket.emit('sendContactForm', askData)
                }
            }
        },
        returnAccueil(){
            this.askSended = false
            this.$router.push({ name: 'accueil' })
        }
      },
      created(){
        this.sockets.subscribe('contactFormSended', () => {
          this.askSended = true
        })
      },
      components: { VueRecaptcha }
   }
  </script>
  <style>
    .myForm{
      border: 3px solid;
      border-radius: 50px;
      border-top-left-radius: 0;
      border-color: rgb(174, 141, 187);
      background-color: rgb(253, 247, 255);
    }
  </style>