<template>
  <v-container>
    <v-row class="mt-8" justify="space-between" align="center">
      <v-col cols="12" md="10">
        <p class="display-1 font-weight-bold">Des funérailles simples,</p>
        <p class="display-1 font-weight-bold">à bas prix, écologiquement réfléchies :</p>
        <p class="display-1 font-weight-bold">simplissime.ch</p>
      </v-col>
      <v-col cols="12" md="2" class="d-none d-md-block">
        <v-card
          width="175"
          style="transform: rotate(30deg); border-radius: 100px;background-color: rgb(239,235,233)"
          elevation = 10
        >
          <v-card-text>
            <p class="text-center body-1 mt-6 font-weight-bold">Pour l’instant uniquement dans le canton de Fribourg !</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" md="6">
       <v-card
          class="mx-auto pa-6"
          max-width="450"
          shaped
          color="brown lighten-4"
        >
          <v-card-text class="black--text">
            <p class="body-1">La prévoyance funéraire consiste à organiser ses funérailles à l’avance en fonction de sa propre personnalité.</p>
            <p class="body-1">Elle évite également des doutes aux survivants.</p>
            <p class="body-1">Elle offre aussi la possibilité de libérer ses proches du souci financier engendré par les frais liés au décès.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn x-large class="primary" :to="{name: 'prevoyance'}">Premier pas vers la prévoyance</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
       <v-card
          class="mx-auto pa-6"
          max-width="450"
          shaped
          color="brown lighten-4"
        >
          <v-card-text class="black--text">
            <p class="body-1">Vous faites face à un deuil et désirez un service économique, écologique, simple et local ?</p>
            <p class="body-1">En cliquant ci-dessous vous serez dirigé vers notre page pour la réservation d’un service d’incinération.</p>
            <p class="body-1">Si besoin, nous sommes à votre disposition tout au long de la procédure.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn x-large class="warning" :to="{name: 'mandater'}">Nous mandater pour un décès</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <div class="brown lighten-4 pa-8">
          <p class="headline text-center">Vos avantages avec Simplissime</p>
          <ul>
            <li>Bon marché et transparent</li>
            <li>Votre cercueil et urne « made in Switzerland » éco-responsable</li>
            <li>Pas de services supplémentaires inutiles</li>
            <li>Entreprise familiale de pompes funèbres reconnue depuis 1993</li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <p class="headline text-center">Votre contact personnel</p>
    <v-row class="mt-8" justify="center">
      <v-col cols=12 lg=2 md=3 sm=4>
        <v-img src="../assets/portraitLaurent.jpg" alt="Photo portrait Laurent" max-height="333px" max-width="250"></v-img>
        <p class="text-center">Laurent Brülhart</p>
      </v-col>
      <v-col cols=12 lg=5 md=6 sm=8>
        <p>En tant qu'entrepreneur expérimenté, je réponds volontiers à toutes vos questions ouvertes.</p>
        <p>Je vous contacterai après votre réservation pour discuter ensemble de la marche à suivre.</p>
        <v-btn href="tel:0263228686" class="primary">026 322 86 86</v-btn>
        <v-btn :to="{name: 'contact'}" class="primary float-right">Fomulaire de contact</v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-8" justify="center">
      <v-col cols=12 lg=7 md=9 sm=12 class="">
        <p class="headline text-center">Pour en savoir plus</p>
        <p>Le service Simplissime a été développé par Laurent Brülhart fils de Daniel Brülhart qui a fondé les Pompes funèbres Brülhart & fils en 1993 en vieille ville de Fribourg. Cliquez sur le lien ci-dessous pour plus d’information :</p>
        <div class="text-center">
          <v-btn class="primary text-center" href="https://pf.brulhart.ch/presentation/" target="_blank">En savoir plus</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <div class="brown lighten-4 pa-8">
          <p class="headline text-center">Témoignages</p>
          <v-carousel height="auto" show-arrows show-arrows-on-hover hide-delimiters>
            <v-carousel-item>
              <v-sheet color="brown lighten-4 black--text">
                <p class="text-center">« Une famille adorable qui sont à l’écoute et dans l’empathie pour chaque histoire et croyance familiale. Nous avons été grandement soutenus dans notre deuil et l’organisation de l’enterrement a été facilitée grâce aux bons conseils et au professionnalisme de la famille Brülhart. Nous les recommandons vivement ! »</p>
                <p class="text-center"><i>Dana Crouet</i></p>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet color="brown lighten-4 black--text">
                <p class="text-center">« Je tiens à vous remercier pour votre soutien et votre réactivité dans l’organisation des funérailles, sépulture, etc.. pour notre maman. Votre engagement et professionnalisme nous facilite grandement la tâche. »</p>
                <p class="text-center"><i>Fernand Molliet</i></p>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8" justify="center">
      <v-col cols="12" lg=8>
        <div class="black white--text pa-8">
          <iframe height="360" width="100%"
            src="https://www.youtube.com/embed/5w9p2QNhRuY">
          </iframe>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
    }),
  };
</script>