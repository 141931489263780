<template>
  <v-container>
    <v-alert v-model="alert" prominent :type="alertType">
      <v-row align="center">
        <v-col class="grow">{{alertText}}</v-col>
      </v-row>
    </v-alert>
    <div v-if="!alert">
      <h1 class="display-2 font-weight-bold text-center">simplissime.ch</h1>
      <h2 class="mt-8">Collecte des informations, confirmation et envoie de la demande</h2>
      <p>Résponsable de la demande : {{user.prenom + " " + user.nom}}</p>
  
      <v-stepper v-model="stepper">
        <v-stepper-header>
          <v-stepper-step :complete="stepper > 1" step="1">{{titreInfoDefunt}}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 2" step="2">Choix des services</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="stepper > 3" step="3">Résumé et confirmation</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
              <v-form class="myForm pa-6" ref="infoDefunt">
                  <v-row justify="start">
                    <v-col cols="12" xl="4" md="4" class="pb-0">
                      <v-radio-group v-model="defunt.sexe" row @change="this.sexeChanged">
                        <v-radio label="Masculin" value="masculin"></v-radio>
                        <v-radio label="Féminin" value="féminin"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" xl="4" md="4" class="pb-0">
                      <v-select :rules="nameRules" v-if="this.defunt.sexe=='féminin'" outlined :hint="this.hint.feminin.titre" v-model="defunt.titre" :items="['Madame', 'Mademoiselle']" label="Titre"></v-select>
                      <v-text-field v-else outlined :hint="this.hint.masculin.titre" v-model="defunt.titre" label="Titre" disabled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" xl="3" md="4" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.prenom:this.hint.feminin.prenom" v-model="defunt.prenom" label="Prénom"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" md="4" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.nom:this.hint.feminin.nom" v-model="defunt.nom" label="Nom"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="2" md="4" class="pb-0">
                      <v-text-field :rules="nameRules" outlined v-if="this.defunt.sexe=='féminin'" :hint="this.hint.feminin.nomJF" v-model="defunt.nomJF" label="Nom de jeune fille"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="start">
                    <v-col cols="12" xl="2" md="2" class="pb-0">
                      <v-text-field validate-on-blur :rules="dateNaissRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.dateNaissance:this.hint.feminin.dateNaissance" type="date" v-model="defunt.dateNaissance" label="Date de naissance"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" md="5" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.lieuNaissance:this.hint.feminin.lieuNaissance" v-model="defunt.lieuNaissance" label="Lieu de naissance"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center" v-if="this.user.service!='prévoyance'">
                    <v-col cols="12" xl="2" md="2" class="pb-0">
                      <v-text-field validate-on-blur :rules="dateDecesRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.dateDeces:this.hint.feminin.dateDeces" type="date" v-model="defunt.dateDeces" label="Date de décès"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" md="5" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.lieuDeces:this.hint.feminin.lieuDeces" v-model="defunt.lieuDeces" label="Lieu de décès"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" md="5" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.depotCorps:this.hint.feminin.depotCorps" v-model="defunt.depotCorps" :label="this.defunt.sexe=='masculin'?'Lieu actuel du défunt':'Lieu actuel de la défunte'"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" xl="4" md="6" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.adresse:this.hint.feminin.adresse" v-model="defunt.adresse" label="Adresse"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="1" md="2" class="pb-0">
                      <v-text-field validate-on-blur :rules="cpRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.codePostal:this.hint.feminin.codePostal" v-model="defunt.codePostal" type="number" min="0000" max="9999" label="Code postal"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" md="4" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.localite:this.hint.feminin.localite" v-model="defunt.localite" label="Localité"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" xl="2" md="4" class="pb-0">
                      <v-select outlined :rules="nameRules" :hint="this.defunt.sexe=='masculin'?this.hint.masculin.etatCivil:this.hint.feminin.etatCivil" v-model="defunt.etatCivil" :items="defunt.sexe=='masculin'?['Époux de','Veuf','Célibataire','Divorcé']:['Épouse de','Veuve','Célibataire','Divorcée']" label="État civil"></v-select>
                    </v-col>
                    <v-col cols="12" xl="6" md="8" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.conjoint:this.hint.feminin.conjoint" v-model="defunt.conjoint" v-if="!this.defunt.etatCivil.indexOf('Épou')" label="Conjoint"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" xl="2" md="4" class="pb-0">
                      <v-combobox :rules="nameRules" outlined :items="['Catholique','Protestante','Orthodoxe','Musulmane','Juive']" :hint="this.defunt.sexe=='masculin'?this.hint.masculin.confession:this.hint.feminin.confession" v-model="defunt.confession" label="Confession"></v-combobox>
                    </v-col>
                    <v-col cols="12" xl="2" md="4" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.lieuOrigine:this.hint.feminin.lieuOrigine" v-model="defunt.lieuOrigine" label="Lieu d'origine"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="4" md="4" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.profession:this.hint.feminin.profession" v-model="defunt.profession" label="Profession"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" xl="4" md="6" class="pb-0">
                      <v-text-field :rules="nameRules" outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.filiation:this.hint.feminin.filiation" v-model="defunt.filiation" label="Filiation"></v-text-field>
                    </v-col>
                    <v-col cols="12" xl="4" md="6" class="pb-0">
                      <v-textarea outlined :hint="this.defunt.sexe=='masculin'?this.hint.masculin.remarque:this.hint.feminin.remarque" v-model="defunt.remarque" label="Remarque"></v-textarea>
                    </v-col>
                  </v-row>
              </v-form>
            <v-btn class="mt-4" color="primary" @click="saveInfosDefunt">Suivant</v-btn>
          </v-stepper-content>
    
          <v-stepper-content step="2">
            <p class="font-italic">Appelez-nous <a href="tel:0263228686">026 322 86 86</a> si vous avez besoin d’aide pour choisir
              définitivement quels services vous désirez.</p>
              <div class="myForm pa-6">
                <h2 class="headline font-weight-bold mt-5">Le forfait de base Simplissime :</h2>
                <p class="font-italic">Ce forfait contient nos honoraires ainsi que toutes les fournitures nécessaires pour un service 
                  d'incinération respectueux de l'environnement.</p>
                <ul>
                  <li>Les entretiens téléphoniques</li>
                  <li>Tous les transports mortuaires</li>
                  <li>Cercueil et urne Suisse écologique</li>
                  <li>Prise en charge, soins et mise en bière</li>
                  <li>Dépôt du corps dans nos locaux (48h)</li>
                  <li>Retrait des cendres et mise en urne</li>
                  <li>Formalités de décès</li>
                  <li>Remise des cendres à la famille</li>
                  <li>Fournitures diverses</li>
                </ul>
                <p class="display-1 mt-6">Prix forfaitaire {{this.prix.forfait}} CHF</p>
                <h2 class="headline font-weight-bold">Prestations supplémentaires :</h2>
            
                <v-checkbox style="zoom:1.3" disabled v-model="taxesObl" color="red" hint="Prestation de tiers facturé par nous (obligatoire)" persistent-hint>
                  <template v-slot:label>
                    <div class="red--text">Acte de décès et taxe de crémation du crématoire Saint-Léonard</div>
                  </template>
                  <template v-slot:append>
                    <div class="green--text">{{prix.taxes_obl}}.-</div>
                  </template>
                </v-checkbox>
                <v-checkbox style="zoom:1.3" v-model="checkBox[0].checked" :disabled="checkBox[1].checked" color="green" hint="Inclus déplacements, préparation du cercueil avec poignées et pieds, habillement, location matériel chapelle, cartes, registre de condoléances" persistent-hint>
                  <template v-slot:label>
                    <div>Présentation en chapelle cercueil fermé</div>
                  </template>
                  <template v-slot:append>
                    <div>{{prix.cercueil_ferme}}.-</div>
                  </template>
                </v-checkbox>
                <v-checkbox style="zoom:1.3" v-model="checkBox[1].checked" :disabled="checkBox[0].checked" color="green" hint="Inclus déplacements, préparation du cercueil avec poignées, pieds et intérieur capitonné en coton bio, habillement, location matériel chapelle, cartes, registre de condoléances" persistent-hint>
                  <template v-slot:label>
                    <div>Présentation en chapelle cercueil ouvert</div>
                  </template>
                  <template v-slot:append>
                    <div>{{prix.cercueil_ouvert}}.-</div>
                  </template>
                </v-checkbox>
                <v-checkbox style="zoom:1.3" v-model="checkBox[2].checked" color="green" hint="Inclus les formalités pour le cimetière, la livraison de l'urne et un accompagnement personnel à l'inhumation des cendres" persistent-hint>
                  <template v-slot:label>
                    <div>Forfait inhumation des cendres au cimetière</div>
                  </template>
                  <template v-slot:append>
                    <div>{{prix.inhumation_cendre}}.-</div>
                  </template>
                </v-checkbox>
                <v-checkbox style="zoom:1.3" v-model="checkBox[3].checked" color="green" hint="Croix en bois, inscription, livraison" persistent-hint>
                  <template v-slot:label>
                    <div>Croix pour le cimetière avec inscription</div>
                  </template>
                  <template v-slot:append>
                    <div>{{prix.croix_cimetiere}}.-</div>
                  </template>
                </v-checkbox>
                <div class="text-right">
                  <p class="display-1 mt-6">Total {{tot}} CHF</p>
                </div>
              </div>
              <div class="mt-4">
                <v-btn color="secondary" @click="stepper = 1">Précédant</v-btn>
                <v-btn color="primary" @click="saveChoixServices">Suivant</v-btn>
              </div>
          </v-stepper-content>
      
          <v-stepper-content step="3">
            <div class="myForm pa-6 mb-4">
              <h2 class="mb-2">Votre demande concernant le décès de : {{defunt.titre + " " + defunt.prenom + " " + defunt.nom}}</h2>
              <h2 class="headline font-weight-bold mt-2">Le forfait de base Simplissime :</h2>
              <p class="font-italic">Ce forfait contient nos honoraires ainsi que toutes les fournitures nécessaires pour un service 
                d'incinération respectueux de l'environnement.</p>
              <ul>
                <li>Les entretiens téléphoniques</li>
                <li>Tous les transports mortuaires</li>
                <li>Cercueil et urne Suisse écologique</li>
                <li>Prise en charge, soins et mise en bière</li>
                <li>Dépôt du corps dans nos locaux (48h)</li>
                <li>Retrait des cendres et mise en urne</li>
                <li>Formalités de décès</li>
                <li>Remise des cendres à la famille</li>
                <li>Fournitures diverses</li>
              </ul>
                <h2 class="headline font-weight-bold mt-2">Vos choix de prestations supplémentaires :</h2>
                <ul>
                  <li>Acte de décès et taxe de crémation du crématoire Saint-Léonard <i>(obligatoire)</i></li>
                  <li v-if="this.checkBox[0].checked">Présentation en chapelle cercueil fermé</li>
                  <li v-if="this.checkBox[1].checked">Présentation en chapelle cercueil ouvert</li>
                  <li v-if="this.checkBox[2].checked">Forfait inhumation des cendres au cimetière</li>
                  <li v-if="this.checkBox[3].checked">Croix pour le cimetière avec inscription</li>
                </ul>
              <h2 class="text-center mt-2">Total {{tot}} CHF</h2>
            </div>
            <v-form class="myForm pa-6" ref="infoUser">
              <h2>Résponsable de la demande</h2>
              <v-row justify="start">
                <v-col cols="12" md="4">
                  <v-select :rules="nameRules" v-model="user.titre" outlined label="Titre" :items="titres"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field disabled :rules="nameRules" v-model="user.prenom" outlined label="Prénom"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field disabled :rules="nameRules" v-model="user.nom" outlined label="Nom"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col cols="12" md="4">
                  <v-text-field :rules="emailRules" type="email" v-model="user.email" outlined label="E-mail"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field :rules="phoneRules" v-mask="'### ### ## ##'" type="tel" v-model="user.telephone" outlined label="Téléphone"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col cols="12" md="4">
                  <v-text-field :rules="nameRules" v-model="user.adresse" outlined label="Adresse"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field validate-on-blur :rules="cpRules" type="number" v-model="user.cp" min="0000" max="9999" outlined label="Code postal"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field :rules="nameRules" v-model="user.localite" outlined label="Localité"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col cols="12" md="8">
                  <v-radio-group :rules="nameRules" v-model="user.choixPaiement" row>
                    <v-radio label="Paiement en espèces" value="cash"></v-radio>
                    <v-radio label="Virement Banquaire" value="virement"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox :rules="nameRules" @click.stop="showCGV" v-model="cgv" label="J'ai lu et j'accepte les C.G.V."></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
            <div class="mt-4">
              <v-btn small color="secondary" @click="stepper = 2">Précédant</v-btn>
              <v-btn color="primary" @click="confirm">Confirmer et envoyer la demande</v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
    <v-dialog v-model="dialogCGV" persistent scrollable>
      <v-card>
        <v-card-title class="headline">Conditions générale de vente</v-card-title>
          <v-card-text>
            <h3 class="text-center">1. Champ d’application</h3>
            <p>Les conditions générales de vente (ci-après désignées par «CGV») s’appliquent aux
              contrats conclus entre « simplissime.ch » et ses clients via le site internet
              simplissime.ch.</p>
              <p>« simplissime.ch » se réserve le droit de modifier les CGV.</p>
              <p>Sauf convention contraire écrite entre les parties, les CGV font partie intégrante de
                toute commande passée par le client.</p>
              <h3 class="text-center">2. Prestations de simplissime.ch</h3>
              <p>« simplissime.ch » fournit et organise des services funèbres aux clients résidant en
                Suisse.</p>
              <p>En particulier, la fourniture de cercueil et d’urne fabriqués en Suisse, la prise en
                charge du défunt, la mise en bière et les transports mortuaires, le retrait des cendres
                et la mise en urne ainsi que la remise de l'urne à la famille, les formalités envers les
                crématoriums, les cimetières, les communes ainsi que l’Etat civil du lieu de décès
                (fourniture d’un acte de décès) et les autres sociétés ou institutions impliquées dans
                les funérailles, des conseils sur les funérailles ainsi que des services liés aux
                services funéraires.</p>
              <p>« simplissime.ch » s'engage à effectuer les tâches assignées par le client avec soin
                et conscience.</p>
              <p>« simplissime.ch » a le droit d'impliquer des tiers, par exemple des sous-traitants,
                pour exécuter la commande. Dans ce cadre, « simplissime.ch » se réserve le droit de
                transmettre tout ou partie des informations dont il dispose avec la commande
                spécifique à des tiers, notamment à des sous-traitants, tant qu'ils servent à réaliser la
                prestation définie.</p>
              <h4>Limite d'application</h4>
              <p>Sauf conventions particulières, en cas de décès hors des limites du canton de
                Fribourg, les frais de rapatriement ne seront pas assumés par « simplissime.ch », et
                seront facturés par celle-ci aux héritiers ou ayants droit.</p>
              <h3 class="text-center">3. Conditions de services</h3>
              <p>Pour la prestation de services, « simplissime.ch » dépend du fait que le client ou une
                personne de contact qu’il a désigné soit disponible à court terme, en personne ou par
                téléphone, à partir du moment où le contrat est conclu.</p>
              <p>De plus, les documents nécessaires à l'exécution de la prestation doivent être mis à
                disposition immédiatement. C'est la seule façon pour « simplissime.ch » de garantir
                que les services convenus peuvent être exécutés intégralement et à temps.</p>
              <p>Si « simplissime.ch » ne reçoit pas toutes les informations nécessaires à temps, il
                facturera au client tous les frais supplémentaires encourus, y compris les heures de
                travail supplémentaires.</p>
              <h3 class="text-center">4. Commande</h3>
              <h4>Confirmation</h4>
              <p>Une commande doit être passée sous forme numérique (via le site simplissime.ch) et
                nécessite que ces conditions générales aient été lues et acceptées.</p>
              <p>Tout achat effectué avec succès fait l’objet d’un courrier électronique de confirmation.</p>
              <p>Le contrat de vente est réputé conclu au moment de la confirmation de l’achat.</p>
              <h4>Obligation de l’acheteur</h4>
              <p>L’acheteur s’engage à fournir des coordonnées valides lors de la commande et à
                payer le prix demandé en cas de paiement par facture.</p>
              <h4>Exécution</h4>
              <p>La commande n'est exécutée qu'après paiement et confirmation orale entre
                « simplissime.ch » et le client. Aucune commande ne sera exécutée sans contact
                personnel entre le client et « simplissime.ch ». Le client est responsable d'être
                joignable par téléphone après avoir saisi sa commande sur le site simplissime.ch.</p>
              <h4>Résiliation</h4>
              <p>Les deux parties ont le droit de résilier le contrat. La partie qui résilie doit compenser
                intégralement les dépenses déjà engagées par l'autre partie. La résiliation n'est pas
                autorisée si l’exécution du service est entamée. Les frais de résiliation de CHF
                350.00 sont à la charge du client.</p>
              <h3 class="text-center">5. Prix</h3>
              <p>Les prix sont indiqués en Francs Suisses (CHF), TVA incluse.</p>
              <p>« simplissime.ch » facture le montant calculé sur le site Internet simplissime.ch, celui-
                ci comprend le coût de nos fournitures et prestations, les coûts des services
                supplémentaires obligatoires ainsi que le coût des services facultatifs sélectionnés
                par le client.</p>
              <p>Les frais de crémation et d’acte de décès sont facturés par l’intermédiaire de
                « simplissime.ch » et sont obligatoires.</p>
              <p>Tous les frais qui ne font pas partie de l'offre de « simplissime.ch » sont facturés
                directement par les institutions ou prestataires de services correspondants.</p>
              <h3 class="text-center">6. Paiement</h3>
              <p>Le paiement d’une prestation de « simplissime.ch » doit en principe être réglé à
                l’avance directement via la plateforme internet.</p>
              <p>Les retenues de toute nature par le client ainsi que les arrangements de paiements
                ne sont pas autorisées sauf convention écrite entre les parties.</p>
              <p>Si le client choisit un moyen de paiement alternatif dans le formulaire de commande
                les conditions suivantes sont en vigueur :</p>
              <p><strong>Paiement liquide : </strong>remise en main propre contre quittance, avant le début de
                l’exécution du service.</p>
              <p><strong>Paiement sur facture : </strong>(uniquement pour les prévoyances) : net à 30 jours.
                Passé le délai de paiement, un intérêt de retard de 7% et des frais de rappel de CHF
                25.00 sont dus.</p>
              <p><strong>Paiement par virement bancaire : </strong>paiement avant le début de l’exécution du
                service sur le compte <pre>IBAN CH23 0076 8300 1553 3030 8</pre></p>
              <h3 class="text-center">7. Prévoyance funéraire</h3>
              <p>Les frais de funérailles sont dus à la conclusion du contrat de prévoyance.</p>
              <p>Le tarif du forfait de base est garanti sans augmentation pour une période de 5 ans à
                compter de l’entrée en vigueur du contrat.</p>
              <h4>Adaptation</h4>
              <p>Aucune augmentation de tarif ne sera facturée par « simplissime.ch » durant une
                période de 5 ans à compter de l'entrée en vigueur du contrat.</p>
              <p>Un décompte sur nos prestations ainsi que les provisions pour prestations de tiers
                sera effectué lors du décès du contractant.</p>
              <p>En cas d'écart par rapport aux frais convenus dans le contrat de prévoyance,
                « simplissime.ch » s'engage à informer les personnes endeuillées des surcoûts avant
                la prestation.</p>
              <h4>Remboursement</h4>
              <p>Le contrat de prévoyance peut être résilié par écrit par le contractant. Au plus tard
                trois mois après réception de l'avis de résiliation et de toute information nécessaire
                au remboursement, « simplissime.ch » procédera au remboursement sans intérêt.
                Les frais déjà engagés par « simplissime.ch » ainsi que les frais de résiliation de
                350CHF ne peuvent pas être récupérés.</p>
              <h3 class="text-center">8. Protection des données</h3>
              <p>Sous réserve de dispositions contraires des présentes CGV, « simplissime.ch »
                reconnaît la confidentialité des informations que l’acheteur lui communique lors de la
                commande et s’engage à ne pas divulguer ces informations à des tiers.</p>
              <p>L’acheteur dispose d’un droit d’accès et de rectification des données personnelles le
                concernant. Pour exercer ces droits, l’utilisateur s’adressera à « simplissime.ch » en
                justifiant son identité.</p>
              <h3 class="text-center">9. Droit applicable et for</h3>
              <p>En cas de litige, les CGV en vigueur au moment de l’achat sont utilisées comme
                base juridique. Les présentes CGV sont régies par le droit suisse.</p>
              <p>Le for juridique est établi à Fribourg, Suisse.</p>
              <p>Avril 2020</p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="acceptCGV">Accepter</v-btn>
          <v-btn color="red darken-1" text @click="refutCGV">Refuser</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="demandeConfirmed" width="500">
      <v-card>
      <v-card-title class="deep-purple lighten-4" primary-title>
          Demande confirmé ! 
      </v-card-title>
      <v-card-text>
          <p class="headline mt-3">Merci pour votre confiance !</p>
          <p class="headline">Nous entamerons les démarches une fois le montant total payé.
              Nous allons prendre contact avec vous rapidement.</p>
      </v-card-text>
  
      <v-divider></v-divider>
  
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="primary"
          text
          @click="finished"
          >
          Ok
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
  import { mask } from 'vue-the-mask'
  export default {
    directives: {
      mask,
    },
    data: () => ({
      user: {},
      taxesObl: true,
      stepper: 1,
      alert: false,
      alertType: 'error',
      alertText: '',
      cgv: false,
      dialogCGV: false,
      demandeConfirmed: false,
      prix: {},
      checkBox:[
        {checked: false, prix: 0},
        {checked: false, prix: 0},
        {checked: false, prix: 0},
        {checked: false, prix: 0}
      ],
      titres: [
        'Monsieur',
        'Madame',
        'Mademoiselle'
      ],
      nameRules: [
        v => !!v || 'Requis !'
      ],
      dateNaissRules: [
        v => (v && Date.parse(v) > -2516572800000 && Date.parse(v) < Date.now()) || 'La date semble eronnée !'
      ],
      dateDecesRules: [
        v => (v && Date.parse(v) < Date.now() && Date.parse(v) > (Date.now() - 8640000000)) || 'La date semble eronnée !'
      ],
      cpRules: [
        v => v > 999 && v < 10000 || 'Le code posatal semble eronné !'
      ],
      emailRules: [
        v => !!v || 'Requis !',
        v => /.+@.+\..+/.test(v) || 'Veuillez entrer un e-mail valide !'
      ],
      phoneRules: [
        v => !!v || 'Requis !',
        v => (v && v.length >= 13) || 'Veillez entrer un numéro de téléphone valide !'
      ],
      defunt: {
        sexe: 'masculin',
        titre:'Monsieur',
        prenom: '',
        nom: '',
        nomJF: '',
        dateNaissance: '',
        lieuNaissance: '',
        dateDeces: '',
        lieuDeces: '',
        depotCorps: '',
        adresse: '',
        codePostal: '',
        localite: '',
        etatCivil: '',
        conjoint: '',
        confession: '',
        lieuOrigine: '',
        profession: '',
        filiation: '',
        remarque: ''
      },
      hint: {
        masculin: 
          {
            titre: "Titre du défunt",
            prenom: "Prénom du défunt",
            nom: "Nom du défunt",
            dateNaissance: "Date de naissance du défunt",
            lieuNaissance: "Lieu de naissance du défunt",
            dateDeces: "Date de décès du défunt",
            lieuDeces: "Où le défunt est-il décédé ?",
            depotCorps: "Où se trouve le corps du défunt actuellement ?",
            adresse: "Adresse du domicile du défunt",
            codePostal: "CP correspondant",
            localite: "Localité du domicile du défunt",
            etatCivil: "État civil du défunt",
            conjoint: "Exemple: Jacqueline née Seydoux",
            confession: "Confession religieuse du défunt",
            lieuOrigine: "Lieu d'origine du défunt",
            profession: "Profession du défunt ou AVS, AI",
            filiation: "Exemple : Fils de Georges Weber et de Johanna née Gobet",
            remarque: "Vos éventuelles remarques"
  
          },
          feminin: 
          {
            titre: "Titre de la défunte",
            prenom: "Prénom de la défunte",
            nom: "Nom de la défunte",
            nomJF: "Nom de jeune fille de la défunte",
            dateNaissance: "Date de naissance de la défunte",
            lieuNaissance: "Lieu de naissance de la défunte",
            dateDeces: "Date de décès de la défunte",
            lieuDeces: "Où la défunte est-elle décédée ?",
            depotCorps: "Où se trouve le corps de la défunte actuellement ?",
            adresse: "Adresse du domicile de la défunte",
            codePostal: "CP correspondant",
            localite: "Localité du domicile de la défunte",
            etatCivil: "État civil de la défunte",
            conjoint: "Prénom et nom du conjoint",
            confession: "Confession religieuse de la défunte",
            lieuOrigine: "Lieu d'origine de la défunte",
            profession: "Profession de la défunte ou AVS, AI",
            filiation: "Exemple : Fille de Georges Weber et de Johanna née Gobet",
            remarque: "Vos éventuelles remarques"
          }
      }
    }),
    methods: {
      test(){

      },
      showCGV(){
        this.dialogCGV=true
      },
      acceptCGV(){
        this.dialogCGV=false
        this.cgv=true
      },
      refutCGV(){
        this.dialogCGV=false
        this.cgv=false
      },
      finished(){
        this.demandeConfirmed = false
        document.getElementById('navDrawer').style.display = "block"
        this.$router.push({ name: 'accueil' })
      },
      sexeChanged(){
        if(this.defunt.sexe=='masculin'){
          this.defunt.titre = "Monsieur"
        }else{
          this.defunt.titre = ""
        }
      },
      saveInfosDefunt(){
        if(this.$refs.infoDefunt.validate()){
          this.$socket.emit('saveInfosDefunt', {infosDefunt: this.defunt, user: this.user})
        }
      },
      saveChoixServices(){
        let data = {}
        data.choix1 = this.checkBox[0].checked?1:0
        data.choix2 = this.checkBox[1].checked?1:0
        data.choix3 = this.checkBox[2].checked?1:0
        data.choix4 = this.checkBox[3].checked?1:0
        data.token = this.user.token
        this.$socket.emit('saveChoixServices', data)
      },
      confirm(){
        if(this.$refs.infoUser.validate()){
          this.$socket.emit('confirm', this.user)
        }
      }
    },
    computed: {
      titreInfoDefunt(){
        if(this.defunt.sexe=='masculin'){
          return "Informations du défunt"
        }else{
          return "Informations de la défunte"
        }
      },
      tot(){
        let total = this.prix.forfait + this.prix.taxes_obl
        for (let index = 0; index < this.checkBox.length; index++) {
          this.checkBox[index].checked ? total = total + this.checkBox[index].prix : ''
        }
        return total
      }
    },
    created(){
      this.$socket.emit('formNeedData', this.$route.params.token)
      this.sockets.subscribe('sendUserToForm', (data) => {
        this.user = data.user
        this.defunt = data.defunt
        this.user.choix1==1?this.checkBox[0].checked=true:this.checkBox[0].checked=false
        this.user.choix2==1?this.checkBox[1].checked=true:this.checkBox[1].checked=false
        this.user.choix3==1?this.checkBox[2].checked=true:this.checkBox[2].checked=false
        this.user.choix4==1?this.checkBox[3].checked=true:this.checkBox[3].checked=false
        this.prix = data.prix
        this.checkBox[0].prix = data.prix.cercueil_ferme
        this.checkBox[1].prix = data.prix.cercueil_ouvert
        this.checkBox[2].prix = data.prix.inhumation_cendre
        this.checkBox[3].prix = data.prix.croix_cimetiere
      })
      this.sockets.subscribe('tokenNotValid', () =>{
        this.alert = true
        this.alertText = "Votre lien n'est pas valide, veuillez prendre contact avec nous !"
      })
      this.sockets.subscribe('tokenNotActif', () =>{
        this.alert = true
        this.alertText = "Vous avez déjà envoyé la demande, veuillez nous contacter si vous avez des questions !"
      })
      this.sockets.subscribe('infosDefuntSaved', () => {
        this.stepper++
        this.formInfoDefunt = true
      })
      this.sockets.subscribe('choixServicesSauved', () => {
        this.stepper++
      })
      this.sockets.subscribe('demandSaved',() => {
        this.demandeConfirmed=true
      })

    },
    mounted(){
      let navBar = document.getElementById('navDrawer')
      navBar.style.display = "none"
      this.$parent.$parent.$parent.drawer = false
    },
    beforeMount() {
    // window.addEventListener("beforeunload", event => {
    //   event.returnValue = ""
    // })
  }
  }
</script>
<style>
  .myForm{
    border: 3px solid;
    border-radius: 50px;
    border-top-left-radius: 0;
    border-color: rgb(174, 141, 187);
    background-color: rgb(253, 247, 255);
  }
</style>