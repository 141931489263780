<template>
  <v-container>
    <h1 class="display-2 font-weight-bold text-center">Prévoyance funéraire</h1>
    <v-btn small href="simplissime_prévoyance.pdf">version imprimable</v-btn>
    <p class="headline mt-8"><i>Prévoir ses funérailles c’est protéger sa famille, une démarche importante, mais ordinaire de plus en plus rependue.</i></p>
    <p class="headline"><i>C’est en faisant cette réflexion avec vos proches que vous pourrez :</i></p>
    <ul class="font-italic">
      <li><strong>décider</strong> la manière dont se dérouleront vos funérailles.</li>
      <li><strong>garantir</strong> le respect de vos volontés.</li>
      <li><strong>soulager</strong> vos proches de tout souci financier et de toute interrogation.</li>
      <li><strong>avoir</strong> l’esprit libre et profiter de la vie.</li>
    </ul>
    <h2 class="headline font-weight-bold mt-8">Le forfait de base Simplissime :</h2>
    <p class="font-italic">Ce forfait contient nos honoraires ainsi que toutes les fournitures nécessaires pour un service 
      d'incinération respectueux de l'environnement.</p>
    <ul>
      <li>Les entretiens téléphoniques</li>
      <li>Tous les transports mortuaires</li>
      <li>Cercueil et urne Suisse écologique</li>
      <li>Prise en charge, soins et mise en bière</li>
      <li>Dépôt du corps dans nos locaux (48h)</li>
      <li>Retrait des cendres et mise en urne</li>
      <li>Formalités de décès</li>
      <li>Remise des cendres à la famille</li>
      <li>Fournitures diverses</li>
    </ul>
    <p class="display-1 mt-6">Prix forfaitaire {{this.prix.forfait}} CHF</p>
    <h2 class="headline font-weight-bold">Prestations supplémentaires :</h2>

    <v-checkbox style="zoom:1.3" disabled v-model="taxesObl" color="red" hint="Prestation de tiers facturé par nous (obligatoire)" persistent-hint>
      <template v-slot:label>
        <div class="red--text">Acte de décès et taxe de crémation du crématoire Saint-Léonard</div>
      </template>
      <template v-slot:append>
        <div class="green--text">{{prix.taxes_obl}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[0].checked" :disabled="checkBox[1].checked" color="green" hint="Inclus déplacements, préparation du cercueil avec poignées et pieds, habillement, location matériel chapelle, cartes, registre de condoléances" persistent-hint>
      <template v-slot:label>
        <div>Présentation en chapelle cercueil fermé</div>
      </template>
      <template v-slot:append>
        <div>{{prix.cercueil_ferme}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[1].checked" :disabled="checkBox[0].checked" color="green" hint="Inclus déplacements, préparation du cercueil avec poignées, pieds et intérieur capitonné en coton bio, habillement, location matériel chapelle, cartes, registre de condoléances" persistent-hint>
      <template v-slot:label>
        <div>Présentation en chapelle cercueil ouvert</div>
      </template>
      <template v-slot:append>
        <div>{{prix.cercueil_ouvert}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[2].checked" color="green" hint="Inclus les formalités pour le cimetière, la livraison de l'urne et un accompagnement personnel à l'inhumation des cendres" persistent-hint>
      <template v-slot:label>
        <div>Forfait inhumation des cendres au cimetière</div>
      </template>
      <template v-slot:append>
        <div>{{prix.inhumation_cendre}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[3].checked" color="green" hint="Croix en bois, inscription, livraison" persistent-hint>
      <template v-slot:label>
        <div>Croix pour le cimetière avec inscription</div>
      </template>
      <template v-slot:append>
        <div>{{prix.croix_cimetiere}}.-</div>
      </template>
    </v-checkbox>
      <p class="display-1 mt-6 text-right">Total {{tot}} CHF</p>
    <v-row class="mt-8">
      <v-col cols="12">
       <v-card
          class="mx-auto pa-6"
          shaped
          color="brown lighten-4"
        >
          <v-card-text>
            <p class="headline black--text">Définir aujourd’hui vos souhaits dans un contrat de prévoyance funéraire n’est pas irrévocable. Le cours de la vie qui continue peut influencer certaines décisions arrêtées dans le contrat de prévoyance. Pour cela vous pouvez en tout temps modifier les volontés consignées.</p>
            <p class="text-center headline black--text">Je veux qu'on me contact pour un rendez-vous sans engagement : </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn x-large class="primary mt-n6" @click="dialog=true">Fomulaire de contact</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Demande de contact</span>
        </v-card-title>
        <v-card-text>
          <v-form lazy-validation v-model="formValid" ref="demande">
            <v-container>
              <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field :rules="nameRules" label="Prénom" required v-model="ask.prenom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field :rules="nameRules" label="Nom" required v-model="ask.nom"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :rules="emailRules" type="email" label="Email" required v-model="ask.email"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :rules="phoneRules" v-mask="'### ### ## ##'" type="tel" label="Téléphone" required v-model="ask.telephone"></v-text-field>
                  </v-col>
                </v-row>
            </v-container>
            </v-form>
          <vue-recaptcha sitekey="6Lcb0uIUAAAAAAX5_D5Bx4NDBm-ofukA8Et527uM"></vue-recaptcha>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="sendAskService">Envoyer</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar color="error" v-model="infoRecaptcha">Veuillez compléter le reCAPTCHA !<v-btn text @click="infoRecaptcha = false">Fermer</v-btn></v-snackbar>
    </v-dialog>
    
    <v-dialog v-model="askSended" width="500">
      <v-card>
        <v-card-title class="headline">Demande envoyée avec succès !</v-card-title>
        <v-card-text class="body-1">
          Merci pour votre demande ! Nous allons vous contacter par téléphone dans les plus brefs délais. Si après 1 heure nous ne vous avons pas contacté, veuillez nous téléphoner au 026 322 86 86. Merci 
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="askSended = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import { mask } from 'vue-the-mask'
  export default {
    directives: {
      mask,
    },
    data: () => ({
      dialog: false,
      taxesObl: true,
      infoRecaptcha: false,
      askSended: false,
      formValid: false,
      prix: {},
      ask: {
        prenom: '',
        nom: '',
        email: '',
        telephone: ''
      },
      nameRules: [
        v => !!v || 'Requis !'],
      emailRules: [
        v => !!v || 'Requis !',
        v => /.+@.+\..+/.test(v) || 'Veuillez entrer un e-mail valide !'
      ],
      phoneRules: [
        v => !!v || 'Requis !',
        v => (v && v.length >= 13) || 'Veillez entrer un numéro de téléphone valide !'
      ],
      checkBox:[
        {checked: false, prix: 0},
        {checked: false, prix: 0},
        {checked: false, prix: 0},
        {checked: false, prix: 0}
      ]
    }),
    methods: {
      test(){
        // this.$socket.emit('test')
        console.log(window.grecaptcha.getResponse())
      },
      sendAskService(){
        if(window.grecaptcha.getResponse()!=''){
          if(this.$refs.demande.validate()){
            let askData = {
              token: window.grecaptcha.getResponse(),
              choice: this.checkBox,
              persoInfo: this.ask,
              service: "prévoyance"
            }
            this.$socket.emit('sendAskService', askData)
          }
        }else{
          this.infoRecaptcha=true
        }
      }
    },
    created(){
      this.$socket.emit('needData')
      this.sockets.subscribe('fetch', (data) => {
        this.prix = data
        this.checkBox[0].prix = data.cercueil_ferme
        this.checkBox[1].prix = data.cercueil_ouvert
        this.checkBox[2].prix = data.inhumation_cendre
        this.checkBox[3].prix = data.croix_cimetiere
      })
      this.sockets.subscribe('error', (data) => {
        console.log(data)
      })
      this.sockets.subscribe('askSended', () => {
        this.dialog = false
        this.askSended = true
      })
    },
    computed: {
      tot(){
        let total = this.prix.forfait + this.prix.taxes_obl
        for (let index = 0; index < this.checkBox.length; index++) {
          this.checkBox[index].checked ? total = total + this.checkBox[index].prix : ''
        }
        return total
      },

    },
    components: { VueRecaptcha }
  //    watch: {
  //     'checkBox': {
  //       handler: function(){
  //         this.total()
  //       },
  //       deep: true
  //     }
  //   }
 }
</script>