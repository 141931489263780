<template>
  <v-container>
    <h1 class="display-2 font-weight-bold text-center">Nous mandater pour un décès</h1>
    <v-btn small href="simplissime_nous_mandater.pdf">version imprimable</v-btn>
    <p class="font-italic font-weight-light text-center mt-5">Nous attirons votre attention sur le fait que pour l’instant nous proposons notre service uniquement dans le canton de Fribourg.</p>
    <h2 class="headline font-weight-bold mt-5">Le forfait de base Simplissime :</h2>
    <p class="font-italic">Ce forfait contient nos honoraires ainsi que toutes les fournitures nécessaires pour un service 
      d'incinération respectueux de l'environnement.</p>
    <ul>
      <li>Les entretiens téléphoniques</li>
      <li>Tous les transports mortuaires</li>
      <li>Cercueil et urne Suisse écologique</li>
      <li>Prise en charge, soins et mise en bière</li>
      <li>Dépôt du corps dans nos locaux (48h)</li>
      <li>Retrait des cendres et mise en urne</li>
      <li>Formalités de décès</li>
      <li>Remise des cendres à la famille</li>
      <li>Fournitures diverses</li>
    </ul>
    <p class="display-1 mt-6">Prix forfaitaire {{this.prix.forfait}} CHF</p>
    <h2 class="headline font-weight-bold">Prestations supplémentaires :</h2>

    <v-checkbox style="zoom:1.3" disabled v-model="taxesObl" color="red" hint="Prestation de tiers facturé par nous (obligatoire)" persistent-hint>
      <template v-slot:label>
        <div class="red--text">Acte de décès et taxe de crémation du crématoire Saint-Léonard</div>
      </template>
      <template v-slot:append>
        <div class="green--text">{{prix.taxes_obl}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[0].checked" :disabled="checkBox[1].checked" color="green" hint="Inclus déplacements, préparation du cercueil avec poignées et pieds, habillement, location matériel chapelle, cartes, registre de condoléances" persistent-hint>
      <template v-slot:label>
        <div>Présentation en chapelle cercueil fermé</div>
      </template>
      <template v-slot:append>
        <div>{{prix.cercueil_ferme}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[1].checked" :disabled="checkBox[0].checked" color="green" hint="Inclus déplacements, préparation du cercueil avec poignées, pieds et intérieur capitonné en coton bio, habillement, location matériel chapelle, cartes, registre de condoléances" persistent-hint>
      <template v-slot:label>
        <div>Présentation en chapelle cercueil ouvert</div>
      </template>
      <template v-slot:append>
        <div>{{prix.cercueil_ouvert}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[2].checked" color="green" hint="Inclus les formalités pour le cimetière, la livraison de l'urne et un accompagnement personnel à l'inhumation des cendres" persistent-hint>
      <template v-slot:label>
        <div>Forfait inhumation des cendres au cimetière</div>
      </template>
      <template v-slot:append>
        <div>{{prix.inhumation_cendre}}.-</div>
      </template>
    </v-checkbox>
    <v-checkbox style="zoom:1.3" v-model="checkBox[3].checked" color="green" hint="Croix en bois, inscription, livraison" persistent-hint>
      <template v-slot:label>
        <div>Croix pour le cimetière avec inscription</div>
      </template>
      <template v-slot:append>
        <div>{{prix.croix_cimetiere}}.-</div>
      </template>
    </v-checkbox>
    <v-row align="center" justify="space-around">
      <v-col cols="12" md="6">
        <v-btn x-large class="warning" @click="dialog=true">Réserver un service funèbre</v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <p class="display-1 mt-6 text-right">Total {{tot}} CHF</p>
      </v-col>
    </v-row>
    <hr style="border: 10px solid; color: rgb(177,174,173); border-radius: 10px;">
     <p class="mt-6">Pour une raison de simplicité, nous n’organisons pas de cérémonie. Au cas où vous désiriez un 
      service traditionnel à prix spécial, contactez-nous directement.</p>
      <div class="text-center">
        <v-btn :to="{name: 'contact'}" large class="info">Formulaire de contact</v-btn>
      </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Envoyer la demande</span>
        </v-card-title>
        <v-card-text>
          <v-form lazy-validation v-model="formValid" ref="demande">
            <v-container>
              <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field :rules="nameRules" label="Prénom" required v-model="ask.prenom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field :rules="nameRules" label="Nom" required v-model="ask.nom"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :rules="emailRules" type="email" label="E-mail" required v-model="ask.email"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field :rules="phoneRules" v-mask="'### ### ## ##'" type="tel" label="Téléphone" required v-model="ask.telephone"></v-text-field>
                  </v-col>
                </v-row>
            </v-container>
            </v-form>
          <vue-recaptcha sitekey="6Lcb0uIUAAAAAAX5_D5Bx4NDBm-ofukA8Et527uM"></vue-recaptcha>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="sendAskService">Envoyer</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar color="error" v-model="infoRecaptcha">Veuillez compléter le reCAPTCHA !<v-btn text @click="infoRecaptcha = false">Fermer</v-btn></v-snackbar>
    </v-dialog>
    
    <v-dialog v-model="askSended" width="auto">
      <v-card>
        <v-card-title class="headline">Demande envoyée avec succès !</v-card-title>
        <v-card-text class="body-1">
          Merci pour votre demande ! Nous allons vous contacter par téléphone dans les plus brefs délais. Si après 1 heure nous ne vous avons pas contacté, veuillez nous téléphoner au 026 322 86 86. Merci 
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="askSended = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="askNotSended" width="auto">
      <v-card>
        <v-card-title class="headline">Une erreur est survenue lors de l'envois du formulaire !</v-card-title>
        <v-card-text class="body-1">
          Malheureusement, votre demande ne nous est pas parvenue ! Veuillez essayer plus tard ou nous appeler au 026 322 86 86 Merci
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="askNotSended = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import { mask } from 'vue-the-mask'
  export default {
    directives: {
      mask,
    },
    data: () => ({
      dialog: false,
      taxesObl: true,
      infoRecaptcha: false,
      askSended: false,
      askNotSended: false,
      formValid: false,
      prix: {},
      ask: {
        prenom: '',
        nom: '',
        email: '',
        telephone: ''
      },
      nameRules: [
        v => !!v || 'Requis !'],
      emailRules: [
        v => !!v || 'Requis !',
        v => /.+@.+\..+/.test(v) || 'Veuillez entrer un e-mail valide !'
      ],
      phoneRules: [
        v => !!v || 'Requis !',
        v => (v && v.length >= 13) || 'Veillez entrer un numéro de téléphone valide !'
      ],
      checkBox:[
        {checked: false, prix: 0},
        {checked: false, prix: 0},
        {checked: false, prix: 0},
        {checked: false, prix: 0}
      ]
    }),
    methods: {
      test(){
        // this.$socket.emit('test')
        console.log(window.grecaptcha.getResponse())
      },
      sendAskService(){
        if(window.grecaptcha.getResponse()!=''){
          if(this.$refs.demande.validate()){
            let askData = {
              token: window.grecaptcha.getResponse(),
              choice: this.checkBox,
              persoInfo: this.ask,
              service: "service funèbre"
            }
            this.$socket.emit('sendAskService', askData)
          }
        }else{
          this.infoRecaptcha=true
        }
      }
    },
    created(){
      this.$socket.emit('needData')
      this.sockets.subscribe('fetch', (data) => {
        this.prix = data
        this.checkBox[0].prix = data.cercueil_ferme
        this.checkBox[1].prix = data.cercueil_ouvert
        this.checkBox[2].prix = data.inhumation_cendre
        this.checkBox[3].prix = data.croix_cimetiere
      })
      this.sockets.subscribe('askNotSended', () => {
        this.dialog = false
        this.askNotSended = true
      })
      this.sockets.subscribe('askSended', () => {
        this.dialog = false
        this.askSended = true
      })
    },
    computed: {
      tot(){
        let total = this.prix.forfait + this.prix.taxes_obl
        for (let index = 0; index < this.checkBox.length; index++) {
          this.checkBox[index].checked ? total = total + this.checkBox[index].prix : ''
        }
        return total
      },

    },
    components: { VueRecaptcha }
  //    watch: {
  //     'checkBox': {
  //       handler: function(){
  //         this.total()
  //       },
  //       deep: true
  //     }
  //   }
 }
</script>