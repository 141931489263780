<template>
  <v-app>
    <v-navigation-drawer
      app
      disable-resize-watcher
      temporary
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      class="brown lighten-4"
    >
      <v-list>
        <v-list-item :to="{name: 'accueil'}" exact>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name: 'mandater'}" exact>
          <v-list-item-content>
            <v-list-item-title>Nous mandater</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{name: 'prevoyance'}" exact>
          <v-list-item-content>
            <v-list-item-title>Prévoyance</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon id="navDrawer" @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <span class="hidden-xs-and-down">Simplissime</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        Contactez-nous :
        <a href="tel:+41263228686" class="mr-6">026 322 86 86</a>
        <a href="mailto:contact@simplissime.ch">contact@simplissime.ch</a>
      </div>
    </v-app-bar>

    <v-content style="background-color: rgb(239,235,233)">
      <router-view></router-view>
    </v-content>

    <v-footer app>
      <span>&copy; {{ (new Date()).getFullYear() }} — SebSolutions</span>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    drawer: false
  })
};
</script>